import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"
import { Link } from "gatsby"

import AtractionItem from "./atractionItem"

const AtractionWrapper = styled.section`
  .atractions-container {
    padding-top: 4rem;
    padding-bottom: 4rem;
    article {
      display: flex;
      flex-direction: column;
    }
    .header {
      text-align: center;
    }
  }
  .atraction-items-container {
    padding: 2rem 0;
    display: flex;
    justify-content: space-around;
    @media (max-width: 925px) {
      flex-direction: column;
      align-items: center;
    }
  }
`

const Atractions = ({ atractions, atractionItems, id }) => {
  let allAtractionBtn = null

  if (atractionItems.length > 2) {
    allAtractionBtn = (
      <Link className="to-group-link" to="/atrakcje/">
        {atractions.button}
      </Link>
    )
  }

  return (
    <AtractionWrapper>
      <div className="container atractions-container" id={id}>
        <article>
          <header>
            <div className="header">
              <h1>{atractions.title}</h1>
              <h2>{atractions.subtitle}</h2>
            </div>
          </header>
          <div className="atraction-items-container">
            {atractionItems.map(atraction => (
              <AtractionItem
                key={atraction.node.id}
                title={atraction.node.frontmatter.title}
                slug={atraction.node.fields.slug}
                image={
                  atraction.node.frontmatter.atractionImage.image
                    .childImageSharp.fluid
                }
                excerpt={atraction.node.excerpt}
              />
            ))}
          </div>
          {allAtractionBtn}
        </article>
      </div>
    </AtractionWrapper>
  )
}

Atractions.propTypes = {
  atraction: PropTypes.object,
  atractionItem: PropTypes.array,
  id: PropTypes.string,
}

Atractions.defaultProps = {
  atractions: {},
  atractionItems: [],
  id: "atractions",
}

export default Atractions
