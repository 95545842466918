import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const CTABottomWrapper = styled.section`
  background: url(${({ bgImage }) => bgImage}) no-repeat center center fixed;
  background-color: #929d9e;
  background-size: cover;
  height: 100%;
  position: relative;
  @media (max-width: 820px) {
    background-attachment: initial;
  }
  article {
    z-index: 100;
  }
  .ctab-container {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    h1 {
      text-align: center;
      color: white;
      text-shadow: 1px 1px 5px black;
      opacity: 1;
      margin-bottom: 2rem;
    }
  }
`

const CallToActionBottom = ({ ctaBottom }) => {
  let imageBg
  if (ctaBottom.image.childImageSharp) {
    imageBg = ctaBottom.image.childImageSharp.fluid.src
  } else {
    imageBg = ctaBottom.image
  }
  return (
    <CTABottomWrapper bgImage={imageBg}>
      <div />
      <article>
        <div className="container ctab-container">
          <header>
            <h1>{ctaBottom.title}</h1>
          </header>
          <Link className="to-item-link" to="/kontakt/">
            {ctaBottom.button}
          </Link>
        </div>
      </article>
    </CTABottomWrapper>
  )
}

export default CallToActionBottom
