import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"

const PricingWrapper = styled.section`
  .pricing-container {
    max-width: 800px;
    width: 80%;
    padding: 4rem 0;
    h1 {
      text-align: center;
    }
    .description {
      padding: 2rem 0;
      text-align: justify;
      text-justify: inter-word;
    }
  }
`

const Pricing = ({ pricing, id }) => (
  <PricingWrapper>
    <div className="container pricing-container" id={id}>
      <article>
        <header>
          <h1>{pricing.title}</h1>
        </header>
        <ReactMarkdown className="description" source={pricing.description} />
      </article>
    </div>
  </PricingWrapper>
)

export default Pricing
