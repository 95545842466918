import React from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { Link } from "gatsby"

const CTAWrapper = styled(animated.section)`
  width: 100vw;
  height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: absolute;
  top: 100px;
  left: 0;
  will-change: opacity, transform;

  div {
    background-color: rgba(225, 225, 225, 0.9);
    padding: 2rem 4rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    h1 {
      margin-bottom: 2rem;
      font-family: "Lato", sans-serif;
      text-align: center;
    }

    p {
      text-align: center;
      max-width: 600px;
      font-size: 1.2rem;
    }
  }
`

const CallToAction = ({ cta }) => {
  const fade = useSpring({
    delay: "400",
    opacity: 1,
    transform: "translateY(0)",
    from: {
      opacity: 0,
      transform: "translateY(150px)",
    },
  })
  return (
    <CTAWrapper style={fade}>
      <div>
        <h1>{cta.title}</h1>
        <p>{cta.subtitle}</p>
        <Link className="to-item-link" to="/kontakt/">
          {cta.button}
        </Link>
      </div>
    </CTAWrapper>
  )
}

export default CallToAction
