import React from "react"
import styled from "styled-components"

import { IoMdCheckmark } from "react-icons/io"

const ServicesWrapper = styled.section`
  .services-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    h2 {
      text-align: center;
    }
    .services-items {
      display: flex;
      justify-content: space-between;

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 0.8rem;
        div {
          display: flex;
          align-items: center;
          margin-right: 1rem;
        }
      }
      .items-col-1,
      .items-col-2 {
        margin-right: 1rem;
      }
      @media (max-width: 820px) {
        display: block;
      }
    }
  }
`

const Services = ({ services }) => (
  <ServicesWrapper>
    <div className="container services-container">
      <article>
        <header>
          <h2>{services.title}</h2>
        </header>
        <div className="services-items">
          <div className="items-col-1">
            {services.items.itemsCol1.map(item => (
              <div className="item" key={item}>
                <div>
                  <IoMdCheckmark />
                </div>
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div className="items-col-2">
            {services.items.itemsCol2.map(item => (
              <div className="item" key={item}>
                <div>
                  <IoMdCheckmark />
                </div>
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div className="items-col-3">
            {services.items.itemsCol3.map(item => (
              <div className="item" key={item}>
                <div>
                  <IoMdCheckmark />
                </div>
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </article>
    </div>
  </ServicesWrapper>
)

export default Services
