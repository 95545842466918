import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/carousel"
import CallToAction from "../components/callToAction"
import AboutUs from "../components/aboutUs"
import Rooms from "../components/rooms"
import Atractions from "../components/atractions"
import Services from "../components/services"
import Pricing from "../components/pricing"
import CallToActionBottom from "../components/callToActionBottom"
import Contact from "../components/contact"

// TODO: Mapa Strony

export const HomePageTemplate = ({
  images,
  cta,
  about,
  rooms,
  services,
  ctaBottom,
  atractions,
  pricing,
  contact,
  roomsItems,
  atractionsItems,
}) => (
  <>
    <Carousel images={images} />
    <CallToAction cta={cta} />
    <AboutUs about={about} id="about" />
    <Rooms rooms={rooms} roomsItems={roomsItems} id="rooms" />
    <Services services={services} />
    <CallToActionBottom ctaBottom={ctaBottom} />
    <Atractions
      atractions={atractions}
      atractionItems={atractionsItems}
      id="atractions"
    />
    <Pricing pricing={pricing} id="pricing" />
    <section>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2597.225016017741!2d22.372610215988974!3d49.38573537934261!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473bf66e64bf3ad1%3A0xf8193acaef07e088!2sBerezka+49%2C+38-610+Berezka!5e0!3m2!1spl!2spl!4v1553365263265"
        style={{
          border: "0",
          width: "100vw",
          height: "70vh",
          margin: "0",
        }}
        allowFullScreen
        frameBorder="0"
      />
    </section>
    <Contact id="contact" contact={contact} />
  </>
)

class HomePage extends React.Component {
  render() {
    const { data } = this.props
    const { frontmatter } = data.homePageData.edges[0].node
    const { edges: roomsItems } = data.roomData
    const { edges: atractionsItems } = data.atractionData
    const { seo } = frontmatter

    return (
      <Layout>
        <SEO
          title={seo.browserTitle}
          description={seo.description}
          keywords={seo.keywords}
        />
        <HomePageTemplate
          images={frontmatter.cta.images}
          cta={frontmatter.cta}
          about={frontmatter.about}
          rooms={frontmatter.rooms}
          services={frontmatter.services}
          ctaBottom={frontmatter.ctaBottom}
          atractions={frontmatter.atractions}
          pricing={frontmatter.pricing}
          contact={frontmatter.contact}
          roomsItems={roomsItems}
          atractionsItems={atractionsItems}
        />
      </Layout>
    )
  }
}

HomePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query HomePageQuery {
    homePageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            cta {
              title
              subtitle
              button
              images {
                image {
                  id
                  childImageSharp {
                    id
                    fluid(
                      maxWidth: 1680
                      quality: 100
                      traceSVG: {
                        color: "#ee7879"
                        turnPolicy: TURNPOLICY_MINORITY
                        blackOnWhite: true
                      }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
                imageAlt
              }
            }
            about {
              title
              subtitle
              description
            }
            rooms {
              title
              description
              button
            }
            ctaBottom {
              image {
                childImageSharp {
                  fluid(maxWidth: 1680) {
                    src
                  }
                }
              }
              title
              button
            }
            atractions {
              title
              subtitle
              button
            }
            services {
              title
              items {
                itemsCol1
                itemsCol2
                itemsCol3
              }
            }
            pricing {
              title
              description
            }
            contact {
              title
              description
              phone
              mobile
              email
            }
            seo {
              browserTitle
              description
              keywords
            }
          }
        }
      }
    }
    roomData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "room-template" } } }
      limit: 3
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            area
            capacity
            description
            images {
              image {
                id
                childImageSharp {
                  id
                  fluid(maxWidth: 920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
    }
    atractionData: allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: ASC }
      filter: { frontmatter: { templateKey: { eq: "atraction-page" } } }
      limit: 3
    ) {
      edges {
        node {
          id
          excerpt(truncate: false, pruneLength: 190)
          fields {
            slug
          }
          frontmatter {
            title
            atractionImage {
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
    }
  }
`

export default HomePage
