import React from "react"
import PropTypes from "prop-types"
import { Transition, animated } from "react-spring/renderprops"
import styled from "styled-components"

import PreviewCompatibleImage from "./previewCompatibleImage"

const CarouselWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: calc(100vh - 160px);
  div {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    will-change: transform, opacity;
  }
`

class Carousel extends React.PureComponent {
  state = { index: 0 }

  componentDidMount() {
    this.slideChangeInterval = setInterval(this.toggle, 4000)
  }

  componentWillUnmount() {
    clearInterval(this.slideChangeInterval)
  }

  toggle = e => {
    this.setState(state => ({
      index: state.index === 3 ? 0 : state.index + 1,
    }))
    clearInterval(this.slideChangeInterval)
    this.slideChangeInterval = setInterval(this.toggle, 4000)
  }

  render() {
    const slides = this.props.images.map(item => style => (
      <animated.div style={style} key={item.image.id}>
        <PreviewCompatibleImage imageInfo={item} />
      </animated.div>
    ))

    return (
      <CarouselWrapper>
        <Transition
          native
          reset
          unique
          items={this.state.index}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {index => slides[index]}
        </Transition>
      </CarouselWrapper>
    )
  }
}

Carousel.propTypes = {
  images: PropTypes.array.isRequired,
}

Carousel.defaultProps = {
  images: [],
}

export default Carousel
