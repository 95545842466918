import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"

const AboutUsWrapper = styled.section`
  .about-container {
    max-width: 800px;
    width: 80%;
    padding: 4rem 0;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    ::after {
      content: "";
      width: 40px;
      height: 2px;
      background-color: rgba(238, 120, 121, 0.6);
      display: block;
      margin: 1rem 0;
    }

    h1,
    h2 {
      text-align: center;
      font-family: "Lato", sans-serif;
    }

    h2 {
      margin: 0;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

const AboutUs = ({ about, id }) => (
  <AboutUsWrapper>
    <div className="container about-container" id={id}>
      <article>
        <header>
          <div className="header">
            <h1>{about.title}</h1>
            <h2>{about.subtitle}</h2>
          </div>
        </header>
        <div className="content">
          <ReactMarkdown className="description" source={about.description} />
          <Link className="to-item-link" to="/o-nas/">
            Więcej o nas
          </Link>
        </div>
      </article>
    </div>
  </AboutUsWrapper>
)

export default AboutUs
